import "bootstrap/dist/css/bootstrap.min.css"
import React, { useCallback, useContext, useEffect } from "react"

import { useSnackbar } from "notistack"
import { CalculosContextProvider } from "./contexts/CalculosContext"
import { LoadingContext } from "./contexts/LoadingContext"
import SiteContext from "./contexts/SiteContext"

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"

import {
    AplicativoDadosUrl,
    AtualizarDispositivoUsuario,
    cripto,
    LoginGeral,
    RegistrarLogErro,
    verificarLocalStorageAppDados,
    verificarLocalStorageCardapio,
    verificarLocalStorageCarrinho,
    verificarLocalStorageEstabelecimentoAtual,
    verificarLocalStorageIzza,
    verificarLocalStorageUsuario,
    verifyNotification,
} from "./services/functions"

import { deleteCardapioStorage, setCardapioStorage } from "./services/StorageManager"

import AvisoLGPD from "./components/AvisoLGPD"
import SteppersDicas from "./components/SteppersDicas"
import LoadingDados from "./pages/LoadingDados/index"
import Routes from "./routes"

import DialogLogin, { LoginDialogContextProvider } from "./components/LoginDialog"

import flatErro from "./assets/fail-flat.svg"
import { AlertContextProvider } from "./contexts/AlertContext"
import { EnderecoContextProvider } from "./contexts/EnderecoContext"
import { ParametrosContextProvider } from "./contexts/ParametrosContext"
import { TextosContextProvider } from "./contexts/TextosContext"
import useCustomHistory from "./hooks/useCustomHistory"

function App() {
    const search = window.location.search
    const [params] = React.useState(new URLSearchParams(search))
    const exibirDicas = false

    const { setLoading, loading } = useContext(LoadingContext)

    const lojaParams = params.get("loja")
    const history = useCustomHistory()
    const [tentativas, setTentativas] = React.useState(0)
    const [open, setOpen] = React.useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }
    const [aplicativoDados, setApp] = React.useState(verificarLocalStorageAppDados()) //ok
    const [temAplicativoDados, setTemAplicativoDados] = React.useState(true)
    const [estabelecimentoAtual, setEstabelecimento] = React.useState(
        verificarLocalStorageEstabelecimentoAtual()
    ) //ok
    const [usuarioLogado, setUser] = React.useState(verificarLocalStorageUsuario()) // ok
    const [carrinho, setCar] = React.useState(verificarLocalStorageCarrinho()) // ok
    const [cardapio, setCard] = React.useState(verificarLocalStorageCardapio()) // ok
    const [izzalogada] = React.useState(verificarLocalStorageIzza()) // dont ok
    // const [continuarSemCadastro,  setContinuarSemCadastro] = React.useState(false);

    const usuarioPedidoMesa = JSON.parse(localStorage.getItem("usuarioPedidoMesaCF"))

    const continuarSemCadastro =
        !usuarioLogado?.logado &&
        !usuarioPedidoMesa?.logado &&
        aplicativoDados?.permitirContinuarSemCadastro &&
        !aplicativoDados.clienteFielStart

    const setAplicativoDados = aplicativoNew => {
        if (aplicativoNew) {
            const stringNew = JSON.stringify(aplicativoNew)
            console.log("==========\n\n setAplicativoDados", aplicativoNew, "\n\n============")

            if (
                aplicativoNew.redirecionarParaSitePrincipal &&
                window.location.href !== "https://" + aplicativoNew.urlAcesso &&
                !window.location.href.includes("localhost")
            ) {
                window.location.href = "https://" + aplicativoNew.urlAcesso
            }
            setApp(JSON.parse(stringNew))
            localStorage.setItem("app", cripto(stringNew))
            localStorage.removeItem("aplicativoCF")
        } else {
            setApp({})
            localStorage.removeItem("app")
            localStorage.removeItem("aplicativoCF")
        }
    }

    const setEstabelecimentoAtual = estabelecimentoNew => {
        if (estabelecimentoNew) {
            const stringNew = JSON.stringify(estabelecimentoNew)
            console.log(
                "==========\n\n setEstabelecimentoAtual",
                estabelecimentoNew,
                "\n\n============"
            )
            setEstabelecimento(JSON.parse(stringNew))
            localStorage.setItem("esta", cripto(stringNew))
        } else {
            setEstabelecimento({})
            localStorage.removeItem("esta")
        }
        localStorage.removeItem("estabelecimentoAtualCF")
    }

    const setCarrinho = carrinhoNew => {
        if (carrinhoNew) {
            const stringNew = JSON.stringify(carrinhoNew)
            console.log("==========\n\n setCarrinho", carrinhoNew, "\n\n============")
            setCar(JSON.parse(stringNew))
            localStorage.setItem("car", cripto(stringNew))
            localStorage.removeItem("carrinhoCF")
        } else {
            setCar({})
            localStorage.removeItem("car")
            localStorage.removeItem("carrinhoCF")
        }
    }

    const setCardapio = cardapioNew => {
        if (cardapioNew) {
            const stringNew = JSON.stringify(cardapioNew)
            if (stringNew === "{}") return
            console.log("==========\n\n setCardapio", cardapioNew, "\n\n============")
            setCard(JSON.parse(stringNew))

            // const parsedCardapio = JSON.parse(stringNew)
            // if (!parsedCardapio?.categorias) retorno = {}
            // setCard(parsedCardapio)

            setCardapioStorage(cardapioNew)
        } else {
            setCard({})
            deleteCardapioStorage()
        }
    }

    const setUsuarioLogado = usuarioNew => {
        if (usuarioNew) {
            const stringNew = JSON.stringify(usuarioNew)
            console.log("==========\n\n setUsuarioLogado", usuarioNew, "\n\n============")
            setUser(JSON.parse(stringNew))
            localStorage.setItem("use", cripto(stringNew))
            localStorage.removeItem("usuarioCF")
        } else {
            setUser(null)
            console.log("==========\n\n setUsuarioLogado null => ", usuarioNew, "\n\n============")
            localStorage.removeItem("use")
            localStorage.removeItem("usuarioCF")
        }
    }

    const qualSiteAcessar = () => {
        let site
        if (aplicativoDados) {
            site = aplicativoDados.urlAcesso
        }
        if (params.get("estabelecimento")) {
            site = params.get("estabelecimento")
        }
        if (!site) {
            site = window.location.hostname
        }
        if (site.includes("localhost") || site.includes("192.168") || site.includes("ngrok.io")) {
            // site = 'apresentacao.pedidosite.com.br'
            // site = 'gustavoapp.clientefiel.app'
            // site = 'pinndelivery.pedidosite.com.br'
            //site = "cristianopizzaria1b0.clientefiel.app"
            site = "pablodelivery.pedidosite.com.br"
        }
        return site
    }

    const atualizarUsuario = useCallback(async (usuarioEnviarPedido = null) => {
        const userLogar = usuarioEnviarPedido ? usuarioEnviarPedido : usuarioLogado

        console.log("atualizarUsuario", atualizarUsuario)
        localStorage.setItem("dateUsuarioCF", new Date())
        if (!aplicativoDados || !userLogar?.logado) {
            console.log("nao atualizarUsuario")
            return false
        }
        console.log("sim atualizarUsuario")
        const data = {
            appNome: aplicativoDados.appNome,
            cliente: {
                email: userLogar.cliente.email,
                hashSenha: userLogar.cliente.hashSenha,
                appNome: aplicativoDados.appNome,
                token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
            },
            localizacao: {
                latitude: "",
                longitude: "",
                token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
                appNome: aplicativoDados.appNome,
            },
            token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
        }
        try {
            if (usuarioPedidoMesa?.logado || params.get("mesa")) {
                return true
            }

            const response = await LoginGeral(data, aplicativoDados)

            if (response.retornoErro) {
                if (response.mensagem.includes("inseridos estão incorretos.")) {
                    localStorage.clear()
                    return false
                }
            } else {
                if (localStorage.getItem("tokenNotificationFirebase")) {
                    if (
                        response.cliente.codigoDispositivo !==
                        localStorage.getItem("tokenNotificationFirebase")
                    ) {
                        response.cliente.codigoDispositivo = localStorage.getItem(
                            "tokenNotificationFirebase"
                        )
                        response.cliente.token = `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`
                        AtualizarDispositivoUsuario(response.cliente, aplicativoDados)
                    }
                }
                // localStorage.setItem('usuarioCF', JSON.stringify(response));
                setUsuarioLogado(response)
                console.log(" ===== usuario atualizado ===== ")
            }
        } catch (error) {
            RegistrarLogErro(error, "AtualizarUsuario", data)
        }
        return true
    }, [])

    const getEstabelecimentoByIdentificador = (identificador, estabelecimentos) => {
        if (!estabelecimentos || !identificador) return null

        for (const estabelecimento of estabelecimentos) {
            if (identificador == estabelecimento.id || identificador == estabelecimento.codigo)
                return estabelecimento
        }

        return null
    }

    const getUniqueEstabelecimentoAppDados = appDados =>
        appDados?.estabelecimentos?.length === 1 ? appDados.estabelecimentos[0] : null

    const realizarLoginMesaObrigatorio = (appDados, uniqueEstabelecimento, mesa) => {
        try {
            if (estabelecimentoAtual || uniqueEstabelecimento) {
                history.push(`/login/pedidoMesa`, {
                    loja: estabelecimentoAtual?.id ?? uniqueEstabelecimento.id,
                    mesa,
                })
                if (!estabelecimentoAtual?.id && uniqueEstabelecimento)
                    setEstabelecimentoAtual(uniqueEstabelecimento)
            } else {
                const estabelecimentoURL = getEstabelecimentoByIdentificador(
                    params.get("loja"),
                    appDados?.estabelecimentos
                )

                if (params.get("loja") && estabelecimentoURL) {
                    setEstabelecimentoAtual(estabelecimentoURL)
                    history.push(`/login/pedidoMesa`, {
                        loja: params.get("loja"),
                        mesa,
                    })
                }
            }
        } catch (error) {
            RegistrarLogErro(error, "realizarLoginMesaObrigatorio", {
                appDados,
                uniqueEstabelecimento,
                mesa,
                loja: params.get("loja"),
            })
            console.error(error)
        }
    }

    const atualizarEstabelecimentoAtual = async appDados => {
        try {
            const permiteDesconectarMesa = appDados?.permitirDesconectarMesa
            const hasUserMesa = usuarioPedidoMesa && usuarioPedidoMesa?.logado
            const hasSettedMesa = params.get("mesa") ?? window.getCookie("mesa-salva")
            const mesa = hasSettedMesa.length ? hasSettedMesa : "--"
            const uniqueEstabelecimento = getUniqueEstabelecimentoAppDados(appDados)

            if (!permiteDesconectarMesa) {
                if (!hasUserMesa && (lojaParams || estabelecimentoAtual || uniqueEstabelecimento)) {
                    realizarLoginMesaObrigatorio(appDados, uniqueEstabelecimento, mesa)
                }
                return
            }

            if (estabelecimentoAtual && appDados.estabelecimentos?.length > 1 && !lojaParams) {
                setEstabelecimentoAtual(null)
            } else {
                if (uniqueEstabelecimento) setEstabelecimentoAtual(uniqueEstabelecimento)
                else if (appDados?.estabelecimentos?.length > 1) {
                    const estabelecimentoURL = getEstabelecimentoByIdentificador(
                        lojaParams,
                        appDados.estabelecimentos
                    )

                    setEstabelecimentoAtual(estabelecimentoURL)
                }
            }
        } catch (error) {
            RegistrarLogErro(error, "atualizarEstabelecimentoAtual", appDados)
        }
    }

    const atualizarAppDados = useCallback(async () => {
        console.log("UPDATE APP DADOS")
        setTentativas(tentativas + 1)
        try {
            const site = qualSiteAcessar()
            const response = await AplicativoDadosUrl(site, aplicativoDados?.appNome)
            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                if (response.mensagem.includes("Não foram encontrados dados"))
                    setTemAplicativoDados(false)
            } else {
                await atualizarEstabelecimentoAtual(response)
                setAplicativoDados(response)
            }
        } catch (error) {
            RegistrarLogErro(error, "atualizarAppDados")
        }
    }, [])

    const aceitarLGPD = useCallback(() => {
        window.setCookie("lgpd-clientefiel", "aceitou", 365)
        setOpen(false)
    }, [])

    const theme = createMuiTheme(
        aplicativoDados
            ? {
                  status: {
                      danger: "#e53e3e",
                      success: "#28a745",
                  },
                  palette: {
                      primary: {
                          light: `${aplicativoDados.corSitePrimaria}`,
                          main: `${aplicativoDados.corSitePrimaria}`,
                          // dark: will be calculated from palette.secondary.main,
                          contrastText: `#fff`,
                      },
                      secondary: {
                          light: `${
                              aplicativoDados.corSiteSecundaria === aplicativoDados.corSitePrimaria
                                  ? "#6c757d"
                                  : aplicativoDados.corSiteSecundaria
                          }`,
                          main: `${
                              aplicativoDados.corSiteSecundaria === aplicativoDados.corSitePrimaria
                                  ? "#6c757d"
                                  : aplicativoDados.corSiteSecundaria
                          }`,
                          contrastText: `#000000`,
                      },
                  },
              }
            : {}
    )

    // console.log('theme', theme)

    const checarURLRedirect = () => {
        const redirectURL = params.get("redirect")

        if (redirectURL) window.location.href = redirectURL
    }

    const checarLinkQRCode = useCallback(() => {
        const link = window.location.href
        const isQRCodeSemLogin =
            !link.includes("/login/") && params.get("mesa") && params.get("pedidoMesa")

        if (isQRCodeSemLogin) {
            history.push("login", {
                mesa: params.get("mesa"),
                loja: params.get("loja"),
                pedidoMesa: true,
            })
        }
    }, [])

    const checarCodigoIndicacao = () => {
        const codigo = params.get("indicacao")

        if (codigo) {
            localStorage.setItem("codigoIndicacao", codigo)
            window.setCookie("codigoIndicacao", codigo, 1)
        }
    }

    useEffect(() => {
        setTimeout(() => setOpen(window.getCookie("lgpd-clientefiel") === "" ? true : false), 1500)

        // const sentryNivel = '´producao'
        const sentryNivel = "beta"
        // deleteCardapioDateStorage()
        let text = window.location.hostname
        let valor = text.replace("www.").substring(text.indexOf(".") + 1)
        let server = valor.replace("www.").substring(0, valor.indexOf("."))

        switch (server) {
            case "clientefiel":
                server = "beta (clientefiel.app)"
                break
            case "pedidosite":
                server = "producao (pedidosite.com.br)"
                break
            default:
                server = window.location.hostname
                break
        }

        //significa que alguns sentrys so vao mandar se for true
        if (sentryNivel.includes("beta") && server.includes("beta")) {
            localStorage.sentry = "true"
        } else if (sentryNivel.includes("producao")) {
            localStorage.sentry = "true"
        } else {
            delete localStorage.sentry
        }

        localStorage.server = server.toUpperCase()

        checarURLRedirect()

        checarLinkQRCode()

        checarCodigoIndicacao()
    }, [])

    useEffect(() => {
        let intervalAppDados
        if (tentativas === 0) {
            //primeria tentativa

            atualizarAppDados()
            atualizarUsuario()
            verifyNotification(aplicativoDados)
        } else {
            intervalAppDados = setInterval(atualizarAppDados, 900000) //15 min
            verifyNotification(aplicativoDados)
        }

        return () => clearInterval(intervalAppDados)
    }, [])

    const context = {
        aplicativoDados,
        setAplicativoDados,

        estabelecimentoAtual,
        setEstabelecimentoAtual,

        usuarioLogado,
        setUsuarioLogado,

        izzalogada,

        carrinho,
        setCarrinho,

        loading,
        setLoading,

        atualizarUsuario,

        cardapio,
        setCardapio,
        setCard,

        continuarSemCadastro,
        // setContinuarSemCadastro

        atualizarAltura,

        realizarLoginMesaObrigatorio,
    }

    console.log("//CONTEXT>", context)

    const android = navigator.userAgent.includes("Android")
    const isFromInstagram = navigator.userAgent.includes("Instagram") && android

    useEffect(atualizarAltura)

    function atualizarAltura(alturaInicial = 0) {
        if (!open || !document.getElementById("aviso-lgpd")) return

        let somaAltura = alturaInicial
        somaAltura +=
            document.getElementById("RodapeComponente")?.getBoundingClientRect().height ?? 0
        somaAltura += document.getElementById("botaoCarrinho")?.getBoundingClientRect().height ?? 0

        if (document.getElementsByClassName("estabelecimento").length > 0) somaAltura = 0

        if (!!somaAltura) {
            setTimeout(
                () => (document.getElementById("aviso-lgpd").style.bottom = `${somaAltura}px`),
                400
            )
        } else {
            document.getElementById("aviso-lgpd").style.bottom = "-120px"
            // Botão está atrapalhando a clicar em adicionar pedido
        }
    }

    if (!temAplicativoDados)
        return (
            <div className="erro-sem-dados">
                <img src={flatErro} alt="Erro" className="pretoEBranco" />
                <span>
                    Não foram encontrados dados do aplicativo {qualSiteAcessar().split(".")[0]}
                </span>
            </div>
        )

    return (
        <SiteContext.Provider value={context}>
            <ParametrosContextProvider>
                <TextosContextProvider>
                    <AlertContextProvider>
                        <LoginDialogContextProvider>
                            <CalculosContextProvider>
                                <EnderecoContextProvider>
                                    <ThemeProvider theme={theme}>
                                        {aplicativoDados?.id ? <Routes /> : <LoadingDados />}
                                        {aplicativoDados?.id ? (
                                            exibirDicas ? (
                                                <SteppersDicas
                                                    aplicativoDados={aplicativoDados}
                                                    open={open}
                                                    aceitarLGPD={aceitarLGPD}
                                                />
                                            ) : (
                                                open && <AvisoLGPD aceitarLGPD={aceitarLGPD} />
                                            )
                                        ) : null}
                                        <DialogLogin />
                                    </ThemeProvider>
                                </EnderecoContextProvider>
                            </CalculosContextProvider>
                        </LoginDialogContextProvider>
                    </AlertContextProvider>
                </TextosContextProvider>
            </ParametrosContextProvider>
        </SiteContext.Provider>
    )
}

export default App
